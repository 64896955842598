<template>
    <div class='shadow d-flex justify-content-center align-items-center h-auto mt-3 info-box-outline'>
        <div class='d-flex h-100 align-items-center flex-column flex-md-row info-box-inside'>
            <div class='d-flex flex-column align-items-center justify-content-center pb-2 info-box-inside-box-one'>
                <div class='d-flex flex-column flex-md-row w-100 short-info-container'>
                    <div class='d-flex flex-row flex-md-column h-auto info-box-upside-box'>
                        <p class='h-auto info-box-upside-key-box-heading'>
                            {{obj.campaign.name}}
                        </p>
                    </div>
                    <div class='d-flex flex-row flex-md-column h-auto info-box-upside-box'>
                        <p class='w-100 h-50 info-box-upside-key-box'>
                            Type
                        </p>
                        <p class='h-auto info-box-upside-value-box'>
                            {{obj.campaign.campaign_type}}
                        </p>
                    </div>
                    <div class='d-flex flex-row flex-md-column h-auto info-box-upside-box'>
                        <p class='w-100 h-50 info-box-upside-key-box'>
                            Urls
                        </p>
                        <p class='h-auto info-box-upside-value-box'>
                            {{obj.tol_urls}}
                        </p>
                    </div>
                    <div class='d-flex flex-row flex-md-column h-auto info-box-upside-box'>
                        <p class='w-100 h-50 info-box-upside-key-box'>
                            Failed Urls
                        </p>
                        <p class='h-auto info-box-upside-value-box'>
                            {{obj.total_failed_tasks}}
                        </p>
                    </div>
                    <div class='d-flex flex-row flex-md-column h-auto info-box-upside-box'>
                        <p class='w-100 h-50 info-box-upside-key-box'>
                            Scraped Urls
                        </p>
                        <p class='h-auto info-box-upside-value-box'>
                            {{obj.total_complete_tasks}}
                        </p>
                    </div>
                </div>
            </div>
            <div class='info-box-inside-box-second-main'>

                <div class='w-50 h-100 d-flex flex-row info-box-inside-box-second'>
                    <h1 class="bi bi-download m-1 complete-cursor" @click="downloadResult(obj.campaign)"></h1>
                    <h1 class="bi bi-bootstrap-reboot m-1 complete-cursor" @click="restartFailed(obj.campaign.id)"></h1>
                    <h1 class="bi bi-trash action-trash-icon m-1" @click="deleteCampaign(obj.campaign.id)"></h1>
                </div>
                <div class='w-50 h-100 d-flex flex-row info-box-inside-box-second'>
                    <router-link :to="{ path: '/CampaignDetail', query: {id: encodeId } }" class='btn btn-primary'>
                        View Dtails
                    </router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { encode } from '@yag/id-hash';
export default {
  name: 'CampaignComplete',
  data(){
    return{
        encodeId: null
    }
  },
  props:{
    obj: {
            type: Object,
            required: true,
    },
    downloadResult: {
            type: Function,
            required: true,
    },
    restartFailed: {
            type: Function,
            required: true,
    },
    deleteCampaign: {
        type: Function,
        required: true,
    },
  },
  mounted (){
    this.encodeId = encode(this.obj.campaign.id)
  }
}
</script>