<template>
    <div v-if="athentication == true" class="d-flex flex-column align-items-center min-vh-100 Page-outline">
        <header>
            <div class="d-flex align-items-end h-100 heading-div">
                <router-link to="/Home" class="bi bi-arrow-left back-icon"></router-link>
            </div>
        </header>
        <DangerAlert :close="closeAlert" v-if="danger" :message="message" />
        <SucessAlert :close="closeSucess" v-if="sucess" :message="message" />
        <main class='container-fluid d-flex flex-column align-items-center mt-2 w-87 h-auto min-vh-100 add-main'>
            <div class="w-100 mb-3 font-weight-bold">
                <h1 class='h-auto w-100 min-vh-6 mb-2 detail-heading'>
                    Create Campaigns
                </h1>
            </div>
            <form style="width: 100%; height: auto">
                <div
                    class='d-flex flex-row justify-content-between align-items-center w-100 text-dark fw-bold min-vh-6 h-auto input-container'>
                    <h3 class='min-vh-7 h-auto d-flex align-items-center status-label'>
                        Name
                    </h3>
                    <input name="campaignName" class="form-control" @input="creatCamapignValidation(event)"
                        v-model="campaignName" id="our-name" type="text" required>
                </div>
                <div
                    class='d-flex flex-row justify-content-between w-100 text-dark fw-bold min-vh-6 h-auto input-container'>
                    <h3 class='min-vh-7 h-auto d-flex align-items-center status-label'>
                        Type
                    </h3>
                    <select name="campaignType" v-model="campaignType" @change="creatCamapignValidation()"
                        id="our-select" class="form-control" required>
                        <option>Select Type</option>
                        <option> PURLSv1</option>
                        <option> PURLSv2</option>
                        <option> CURLSv2</option>

                    </select>
                </div>
                <div
                    class='d-flex flex-row justify-content-between w-100 text-dark fw-bold min-vh-6 h-auto input-container'>
                    <h3 class='min-vh-7 h-auto d-flex align-items-center status-label'>
                        Start by
                    </h3>
                    <select @change="StartbyOptionSelect()" v-model='campaignStartBy' id="our-select-start-by"
                        class="form-control" required>
                        <option>Select Option</option>
                        <option>SCHEDULE</option>
                        <option>MANUAL</option>
                    </select>
                </div>
                <div v-if="startBy == false"
                    class='flex-row justify-content-end w-100 text-dark fw-bold min-vh-6 h-auto input-container-date-time'>
                    <div class="d-flex h-auto min-vh-6 justify-content-between date-box">
                        <div class="d-flex flex-column h-auto min-vh-6 justify-content-between date-box-half">
                            <h3 class='min-vh-6 h-auto d-flex align-items-center justify-content-start date-box-label'>
                                Select Status :
                            </h3>
                            <div class="form-check check-com">
                                <input type="radio" v-model="status" value="PENDING" id="flexRadioDefault1">
                                <label class="radio-font ml-2" for="flexRadioDefault1">
                                    PENDING
                                </label>
                            </div>
                            <div class="form-check check-com">
                                <input type="radio" v-model="status" value="IN_PROGRESS" id="flexRadioDefault2">
                                <label class="radio-font ml-2" for="flexRadioDefault2">
                                    IN_PROGRESS
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="startBy == true"
                    class='d-flex flex-row justify-content-end w-100 text-dark fw-bold min-vh-6 h-auto input-container-date-time-schedule'>
                    <div class="d-flex h-auto min-vh-6 justify-content-between date-box">
                        <div class="d-flex flex-row h-auto min-vh-6 justify-content-between date-box-half">
                            <h3 class='min-vh-6 h-auto d-flex align-items-center justify-content-start date-box-label'>
                                Date From :
                            </h3>
                            <input type="date" @input="creatCamapignValidation" v-model="dateStart" class="date-com" />
                        </div>
                        <div class="d-flex flex-row h-auto min-vh-6 justify-content-between date-box-half">
                            <h3 class='min-vh-6 h-auto d-flex align-items-center justify-content-start date-box-label'>
                                Time From :
                            </h3>
                            <input type="time" @input="creatCamapignValidation" v-model="timeStart" class="date-com" />
                        </div>
                    </div>
                </div>

                <div
                    class='d-flex flex-row justify-content-start w-100 text-dark fw-bold min-vh-6 h-auto input-container'>
                    <h3 class='min-vh-7 h-auto d-flex align-items-center pt-1 status-label'>
                        Set Schedule
                    </h3>
                    <div class="form-check max-vh-6 p-0 check-com-schedule">
                        <input type="checkbox" v-model="isChecked" @change="alwaysCheckbtn()" name="flexRadioDefault"
                            id="always-btn" checked>
                        <label class="radio-font ml-1" for="always-btn">
                            Always
                        </label>
                    </div>
                </div>
                <div v-if="addSchedule"
                    class='d-flex flex-row justify-content-end w-100 text-dark fw-bold min-vh-6 h-auto'
                    id="input-container-schedule-btn">
                    <div class="d-flex h-auto min-vh-6 justify-content-between date-box">
                        <div class="d-flex flex-row h-auto min-vh-6 date-box-half">
                            <button type="button" class="btn btn-primary" @click="addScedulebtn()">Add Schedule</button>
                        </div>
                    </div>
                </div>
                <div v-if="daysTimeCon"
                    class='d-flex flex-row justify-content-end w-100 text-dark fw-bold min-vh-6 h-auto input-container-date-time-schedule'>
                    <div class="d-flex h-auto min-vh-6 justify-content-between date-box">
                        <div class="w-100 h-auto min-vh-6">
                            <div v-for="(day, index) in Week" :key="index" class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" :id="'inlineCheckbox' + index"
                                    :value="day" v-model="Days" checked>
                                <label class="form-check-label font-check-box" :for="'inlineCheckbox' + index">
                                    {{ day }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="daysTimeCon"
                    class='d-flex flex-row justify-content-end w-100 text-dark fw-bold min-vh-6 h-auto input-container-date-time-schedule'>
                    <div class="d-flex h-auto min-vh-6 justify-content-between date-box">
                        <div
                            class="d-flex flex-row h-auto min-vh-6 justify-content-between align-items-center date-box-half-for-set-shedule">
                            <h3 class='min-vh-6 h-auto d-flex align-items-center justify-content-start date-box-label'>
                                Time :
                            </h3>
                            <input v-model="scheduleTimeStart" @input="checkSheduleInput()" type="time"
                                class="date-com-for-set-shedule" />
                            <div class="" style="height: 1px; width: 10%; background-color: #495057"></div>
                            <input v-model="scheduleTimeEnd" @input="checkSheduleInput()" type="time"
                                class="date-com-for-set-shedule" />
                        </div>
                    </div>
                </div>
                <div v-if="!isChecked" class='d-flex w-100 justify-content-end mt-2 align-items-end'
                    id="filebox-footer-schedule">
                    <div class='h-75 justify-content-center mb-2 filebox-footer-row-schedule'>
                        <button @click="removeBtnFunction()" v-if="removeBtn" type="button"
                            class='btn btn-danger btn-sm' id="remove-btn">
                            Remove
                        </button>
                        <button v-if="addingNewScheduleBtn" type="button" class='btn btn-success btn-sm ml-2'
                            @click="selectedDays()">
                            Add
                        </button>
                    </div>
                </div>
                <div class="h-auto w-100 d-flex justify-content-end">
                    <div class="h-auto schedule-box-inside">
                        <div v-for="(obj, index) in Schedules" :key="index" class="mb-4 h-auto schedule-box">
                            <h6 class="d-flex justify-content-center mb-3">Schedule No {{ obj.scheduleNo }}</h6>
                            <h6 class="d-flex">Time : {{ obj.time_start }} to {{ obj.time_end }} PM</h6>
                            <h6 class="d-flex ">Days: <div class="ml-2" v-for="(obj, index) in obj.week_days"
                                    :key="index">{{ obj }}</div>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class='d-flex flex-row w-100 text-dark fw-bold min-vh-6 h-auto input-container-upload-csv'>
                    <h3 class='min-vh-7 h-auto d-flex status-label'>
                        Upload CSV
                    </h3>
                    <div class='rounded addcampaign-file-box' id="dropZone" @dragover.prevent="onDragOver" @dragleave="onDragLeave" @drop="onDrop">
                        <label class='w-100 min-vh-7 d-flex flex-column justify-content-end filebox-label'>
                            <div class='w-100 d-flex align-items-center justify-content-center icon-container'>
                                <h1 class="bi bi-cloud-arrow-up-fill icon-large"></h1>
                            </div>
                            <h4 class='w-100 d-flex align-items-center justify-content-center label-upper-text'>
                                Click To Upload File
                                <input name="fileBox" type='file' @input="handleFileUpload" accept="text/csv"
                                    class="filechangetext" id="filecombox" hidden />
                            </h4>
                            <h5 class='w-100 d-flex align-items-center justify-content-center label-lower-text'>
                                {{ fileName || 'Drag and drop file here' }}
                            </h5>
                        </label>
                    </div>
                </div>
                <div class='w-100 d-flex justify-content-end align-items-end filebox-footer'>
                    <div class='h-75 d-flex justify-content-center filebox-footer-row'>
                        <button type="button" @click="cencel" class='btn btn-danger btn-sm'>
                            Cancel
                        </button>
                        <button type="button" @click="submitForm" class='btn btn-success btn-sm ml-2' id="createbtn"
                            disabled>
                            Create
                        </button>
                    </div>
                </div>
            </form>
        </main>
        <LoadingCheck v-if="isLoading == true" />
    </div>
</template>


<script>
import axios from 'axios';
import { decode } from '@yag/id-hash';
import LoadingCheck from './Loading.vue';
import DangerAlert from "./Danger.vue";
import SucessAlert from "./Sucess.vue";

export default {
    name: 'AddUserCampaign',
    components: {
        LoadingCheck,
        DangerAlert,
        SucessAlert
    },
    data() {
        return {
            Week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            Days: [],
            Schedules: [],
            campaign: {},
            schedule: {},
            scheduleCount: 0,
            campaignName: '',
            campaignType: 'Select Type',
            campaignStartBy: 'Select Option',
            status: 'PENDING',
            startBy: null,
            dateStart: null,
            timeStart: null,
            isChecked: true,
            addSchedule: false,
            daysTimeCon: false,
            scheduleTimeStart: null,
            scheduleTimeEnd: null,
            addingNewScheduleBtn: false,
            removeBtn: false,
            fileBox: [],
            file: null,
            Update: null,
            isLoading: false,
            message: '',
            danger: false,
            sucess: false,
            token: null,
            campaignIdUpdate: null,
            athentication: false,
            fileName: '',
        };
    },
    methods: {
        intializing() {
            this.Week = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
            this.Days = []
            this.Schedules = []
            this.campaign = {}
            this.schedule = {}
            this.scheduleCount = 0
            this.campaignName = ''
            this.campaignType = 'Select Type'
            this.campaignStartBy = 'Select Option',
                this.status = 'PENDING',
                this.startBy = null,
                this.dateStart = null,
                this.timeStart = null,
                this.isChecked = true,
                this.addSchedule = false,
                this.daysTimeCon = false,
                this.scheduleTimeStart = null,
                this.scheduleTimeEnd = null,
                this.addingNewScheduleBtn = false,
                this.removeBtn = false,
                this.fileBox = [],
                this.file = null,
                this.Update = null,
                this.isLoading = false

        },
        selectedDays() {
            this.scheduleCount++;

            this.schedule = {
                week_days: this.Days,
                time_start: this.scheduleTimeStart,
                time_end: this.scheduleTimeEnd,
                scheduleNo: this.scheduleCount
            }
            this.Schedules.push(this.schedule)
            this.daysTimeCon = false
            this.addSchedule = true
            this.addingNewScheduleBtn = false
            this.scheduleTimeStart = null
            this.scheduleTimeEnd = null
            this.Days = []
            if (this.Schedules.length >= 1) {
                this.removeBtn = true
            }

        },
        StartbyOptionSelect() {
            if (this.campaignStartBy === "Select Option") {
                this.startBy = null
            }
            else if (this.campaignStartBy === "SCHEDULE") {
                this.startBy = true
            }
            else if (this.campaignStartBy === "MANUAL") {
                this.startBy = false
            }
            this.creatCamapignValidation()
        },
        alwaysCheckbtn() {
            if (!this.isChecked) {
                this.addSchedule = true
            }
            else {
                this.addSchedule = false;
                this.daysTimeCon = false;

            }
            this.creatCamapignValidation();
        },
        onDragOver(event) {
            event.preventDefault();
            const dropZone = event.currentTarget;
            dropZone.classList.add('dragover');
        },
        onDragLeave(event) {
            const dropZone = event.currentTarget;
            dropZone.classList.remove('dragover');
        },
        onDrop(event) {
            event.preventDefault();
            const dropZone = event.currentTarget;
            dropZone.classList.remove('dragover');

            const files = event.dataTransfer.files;
            if (files.length > 0) {
                this.fileName = files[0].name;
                this.file = files[0]; // Store the file for further use
            }
            this.creatCamapignValidation();
        },
        handleFileUpload(event) {
            this.fileBox = event.target.files;
            if (this.fileBox.length > 0) {
                this.file = this.fileBox[0];
                this.fileName = this.fileBox[0].name;
            }
            this.creatCamapignValidation()
        },
        addScedulebtn() {
            this.daysTimeCon = true
            this.addSchedule = false
        },
        validationForAdd(scheduleBtn) {
            if (this.campaignName.length !== 0 && this.campaignType !== "Select Type" && this.campaignStartBy !== "Select Option" && this.file) {
                if (this.campaignStartBy === 'MANUAL') {
                    this.dateStart = null
                    this.timeStart = null
                    scheduleBtn.disabled = false;

                }
                else if (this.campaignStartBy === 'SCHEDULE') {
                    this.status = 'PENDING'
                    if (this.dateStart !== null && this.timeStart !== null) {
                        scheduleBtn.disabled = false;
                    }
                    else if (this.dateStart === null && this.timeStart === null) {
                        scheduleBtn.disabled = true
                    }
                }
            }
            else {
                scheduleBtn.disabled = true;
            }
        },
        validationForUpdate(scheduleBtn) {
            if (this.campaignName.length !== 0) {
                if (this.campaignStartBy === 'MANUAL') {
                    this.dateStart = null
                    this.timeStart = null
                    scheduleBtn.disabled = false;

                }
                else if (this.campaignStartBy === 'SCHEDULE') {
                    if (this.dateStart !== null && this.timeStart !== null) {
                        scheduleBtn.disabled = false;
                    }
                    else if (this.dateStart === null && this.timeStart === null) {
                        scheduleBtn.disabled = true
                    }
                }
                // else if (this.campaignStartBy === 'Select Option') {
                //     // this.startBy = null
                //     // scheduleBtn.disabled = true
                //     this.StartbyOptionSelect()
                // }
            }
            else {
                scheduleBtn.disabled = true;
            }
        },
        creatCamapignValidation() {
            const scheduleBtn = document.getElementById('createbtn');
            if (this.Update) {
                this.validationForUpdate(scheduleBtn)
            }
            else {
                this.validationForAdd(scheduleBtn)
            }
        },
        async submitFormForAdding() {
            const formData = new FormData()
            formData.append('name', this.campaign.name)
            formData.append('campaign_type', this.campaign.type)
            formData.append('status', this.campaign.status)
            formData.append('start_by', this.campaign.start_by)
            formData.append('tasks_file', this.file)
            if (this.timeStart !== null && this.dateStart !== null) {
                formData.append('start_at', this.campaign.start_at)
            }
            for (var i = 0; i < this.campaign.scraping_schedules.length; i++) {
                const weekDaysArray = this.campaign.scraping_schedules[i].week_days;
                const weekDaysString = weekDaysArray.join(',');
                formData.append('schedule_days', weekDaysString)
                formData.append('schedule_time_start', this.campaign.scraping_schedules[i].time_start)
                formData.append('schedule_time_end', this.campaign.scraping_schedules[i].time_end)
            }
            try {
                const response = await axios.post('/api/v1/campaigns', formData, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.message = `${response.data.name} is created`
                this.isLoading = false
                this.danger = false
                this.sucess = true
                // this.intializing()
                // document.getElementsByClassName("label-lower-text")[0].innerHTML = "Drag and drop file here";
                window.location.reload()

            }
            catch (error) {

                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"
                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else if (statusCode === 422) {
                        this.message = "Something is Missing"
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "Campaign With Name Already Saved"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isLoading = false
            }
        },
        async submitFormForUpdating(campaign_id) {
            var update_campaign = {}
            update_campaign.name = this.campaignName
            update_campaign.status = this.status
            update_campaign.start_by = this.campaignStartBy
            update_campaign.complete = true
            if (this.timeStart !== null && this.dateStart !== null) {
                update_campaign.start_at = this.dateStart + " " + this.timeStart
            }
            try {
                await axios.patch(`/api/v1/campaigns/update/${campaign_id}`, update_campaign, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    },
                })
                this.message = `Updated`
                this.isLoading = false
                this.danger = false
                this.sucess = true;
                this.$router.push({ name: 'AddCampaign' });
                // this.$router.push({
                //     name: 'AddCampaign',
                //     query: {
                //         update: false
                //     }
                // });
                this.$router.push({
                    name: 'AddCampaign',
                    query: {
                        update: false
                    }
                }).then(() => {
                    window.location.reload();
                });

            }
            catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"
                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    } else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    } else if (statusCode === 422) {
                        this.message = "Something is Missing"
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isLoading = false
            }
        },

        async submitForm() {
            this.isLoading = true
            this.campaign = {
                name: this.campaignName,
                type: this.campaignType,
                status: this.status,
                start_by: this.campaignStartBy,
                start_at: this.dateStart + " " + this.timeStart,
                scraping_schedule_type: "PART_TIME",
                scraping_schedules: this.Schedules
            }
            console.log(this.campaign);
            if (this.Update) {
                this.submitFormForUpdating(this.campaignIdUpdate)
            }
            else {
                this.submitFormForAdding()
            }
        },
        checkSheduleInput() {
            if (this.scheduleTimeEnd !== null && this.scheduleTimeStart !== null) {
                this.addingNewScheduleBtn = true
            }
            else {
                this.addingNewScheduleBtn = false
            }
        },
        removeBtnFunction() {
            this.Schedules.pop()
            this.scheduleCount--;
            if (this.Schedules.length === 0) {
                this.removeBtn = false
            }
        },
        async fetchCampaign(campaignId) {
            this.isLoading = true
            try {
                const response = await axios.get(`/api/v1/campaigns/${campaignId}`, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                    }
                })
                console.log(response)
                return response.data.campaign
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"
                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                        this.isLoading = false
                        this.danger = true
                        this.$router.push({ name: 'Home' });
                        return null
                    } else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.isLoading = false
                this.danger = true
                this.sucess = false
                var data = localStorage.getItem("campaignData")
                var data_tol = JSON.parse(data)
                return data_tol
            }

        },
        setData(data_tol, selectElement) {
            this.campaignName = data_tol.name
            this.Schedules = data_tol.scraping_schedules
            this.campaignStartBy = data_tol.start_by
            if (data_tol.status === "PENDING" && (this.campaignStartBy === "SCHEDULE" || this.campaignStartBy === "MANUAL")) {
                if (this.campaignStartBy === "SCHEDULE") {
                    const spaceIndex = data_tol.start_at.indexOf("T");
                    const date = data_tol.start_at.slice(0, spaceIndex);
                    const time = data_tol.start_at.slice(spaceIndex + 1);
                    this.dateStart = date
                    this.timeStart = time
                }

                this.StartbyOptionSelect()
            }
            else {
                var nameCom = document.getElementById('our-name')
                var setSchedule = document.getElementById('always-btn')
                nameCom.disabled = true
                setSchedule.disabled = true
                for (let count = 0; count < selectElement.length; count++) {
                    selectElement[count].disabled = true
                }
            }

        },


        async updateProcess(Id) {
            var data_tol = await this.fetchCampaign(Id)
            if (data_tol === null)
            {
                this.isLoading = false

                return
            }
            const selectElement = document.getElementsByTagName('select')
            const filecombox = document.getElementById('filecombox')
            const alwaysBtn = document.getElementById("always-btn")
            console.log(filecombox)
            filecombox.disabled = true
            selectElement[0].disabled = true
            alwaysBtn.disabled = true
            this.setData(data_tol, selectElement)
            this.isLoading = false
        },
        closeAlert() {
            this.danger = false
        },
        closeSucess() {
            this.sucess = false
        },
        cencel() {
            window.location.reload()
        },
        async get_current_user() {
            try {
                const response = await axios.get(`/api/v1/users/me`, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                    }
                })
                return response.status
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"
                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    } else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                        return
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
            }
        }
    },
    async mounted() {
        try {
            this.token = JSON.parse(localStorage.getItem('token'))
            var responce = await this.get_current_user()
            if (responce !== 200) {
                this.$router.push({ name: 'Login' });
                return
            }

        } catch (error) {
            this.$router.push({ name: 'Login' });
            return
        }
        if (this.token === null) {
            this.$router.push({ name: 'Login' });
            return
        }
        this.Update = this.$route.query.update
        // this.Update = 'false'
        this.Update = this.Update === 'true';
        if (this.Update === undefined) {
            this.Update = false
        }
        else if (this.Update) {
            const Id = decode(this.$route.query.id)
            if (Id === undefined) {
                this.Update = false
                window.location.reload()
            }
            this.campaignIdUpdate = Id
            this.$nextTick(async () => {
                await this.updateProcess(Id);
            });

        }
        this.athentication = true

    },
}

</script>