<template>
    <div v-if="athentication == true" class="d-flex flex-column align-items-center min-vh-100 Page-outline">
        <div class="header-homepage">
            <div class="d-flex align-items-end h-100 heading-div">
                <h1 class="bi bi-box-arrow-right" @click="logOut()">
                </h1>
            </div>
        </div>
        <DangerAlert :close="closeAlert" v-if="danger" :message="message" />
        <SucessAlert :close="closeSucess" v-if="sucess" :message="message" />
        <main class='container-fluid d-flex flex-column mt-2 w-87 h-auto min-vh-100 add-main'>
            <div class="w-100 mb-3 font-weight-bold detail-heading">
                <h1 class="d-flex align-items-center w-80 min-vh-6 mb-3">
                    Your Campaigns
                </h1>
                <div class="d-flex flex-row w-20 mt-2 h-100 add_btn">
                    <div class="d-flex align-items-center w-100 mr-2 h-100">
                        <router-link :to="{ path: '/AddCampaign', query: { update: false } }"
                            class="btn btn-primary ml-1 mb-1 btn-">
                            <i class="bi bi-plus">
                                <span class="add-btn-font-style">Add New</span>
                            </i>
                        </router-link>
                    </div>
                </div>
            </div>
            <h5 class='w-100 mb-2 mt-5 status-heading'>
                Pending
            </h5>
            <div v-if="pending.length === 0" class="no-campaign-message">
                No campaigns
            </div>
            <CampaignPending v-else v-for="(obj, index) in pending" :obj="obj" :key="index" :play="updatePlayPause" :deleteCampaign="deleteCampaign"/>
            <h5 class='w-100 mb-2 mt-5 status-heading'>
                Paused
            </h5>
            <div v-if="paused.length === 0" class="no-campaign-message">
                No campaigns
            </div>
            <CampaignPaused v-else v-for="(obj, index) in paused" :obj="obj" :key="index" :downloadResult="downloadResult" :play="updatePlayPause" :interrupt="interruptCampaign"/>
            <h5 class='w-100 mb-2 mt-5 status-heading'>
                In Progress
            </h5>
            <div v-if="inProgress.length === 0" class="no-campaign-message">
                No campaigns
            </div>
            <CampaignInProgress v-else v-for="(obj, index) in inProgress" :obj="obj" :key="index" :downloadResult="downloadResult" :play="updatePlayPause" :interrupt="interruptCampaign"/>
            <h5 class='w-100 mb-2 mt-5 status-heading'>
                Complete
            </h5>
            <div v-if="complete.length === 0" class="no-campaign-message">
                No campaigns
            </div>
            <CampaignComplete v-else v-for="(obj, index) in complete" :obj="obj" :key="index" :downloadResult="downloadResult" :restartFailed="restartFailedUrls" :deleteCampaign="deleteCampaign"/>
            <h5 class='w-100 mb-2 mt-5 status-heading'>
                Interrupted
            </h5>
            <div v-if="interrupted.length === 0" class="no-campaign-message">
                No campaigns
            </div>
            <CampaignInterrupted v-else v-for="(obj, index) in interrupted" :obj="obj" :key="index" :downloadResult="downloadResult" :restartFailed="restartFailedUrls" :deleteCampaign="deleteCampaign"/>
            <div class="d-flex align-items-center justify-content-center load-more-btn">
                <router-link v-if="currentPage>0" @click="prevPage" :to="{ path: '/Home', query: {page: currentPage - 1} }" type="button" class="btn btn-outline-primary btn-lg">
                    Prev
                </router-link>
                <router-link v-if="currentPage<tol_campaigns - 1" @click="nextPage" :to="{ path: '/Home', query: {page: currentPage + 1} }" type="button" class="btn btn-outline-primary btn-lg">
                    Next
                </router-link>
            </div>
        </main>
        <LoadingCheck v-if="isLoading == true"></LoadingCheck>

    </div>
</template>




<script>
import axios from 'axios';

import CampaignPending from "./Pending.vue";
import CampaignInProgress from "./InProgress.vue";
import CampaignComplete from "./Complete.vue";
import CampaignPaused from "./Paused.vue";
import CampaignInterrupted from "./Interrupted.vue";
import LoadingCheck from "./Loading.vue";
import DangerAlert from "./Danger.vue";
import SucessAlert from "./Sucess.vue";



export default {

    name: 'HomePage',
    components: {
        CampaignPending,
        CampaignPaused,
        CampaignInProgress,
        CampaignComplete,
        CampaignInterrupted,
        LoadingCheck,
        SucessAlert,
        DangerAlert

    },
    data() {
        return {
            campaigns: '',
            pending: [],
            inProgress: [],
            complete: [],
            paused: [],
            interrupted: [],
            display: false,
            isLoading: false,
            message: '',
            danger: false,
            sucess: false,
            token: null,
            tol_campaigns: 0,
            currentPage: 0,
            offset: 0,
            limit: 50,  // change to 50
            athentication: false
        };
    },
    methods: {
        async getCampaigns() {
            this.display = true
            try {
                const response = await axios.get('/api/v1/campaigns/usercampaigns', {
                    params: { 
                        offset: this.offset, 
                        limit: this.limit 
                    },
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                });

                this.campaigns = response.data.campaigns
                this.tol_campaigns = Math.ceil(response.data.total / 50) // Change To 50
                for (let count = 0; count < this.campaigns.length; count++) {
                    if (this.campaigns[count].campaign.status === 'PENDING') {
                        this.pending.push(this.campaigns[count])
                    }
                    else if (this.campaigns[count].campaign.status === 'IN_PROGRESS') {
                        this.inProgress.push(this.campaigns[count])
                    }
                    else if (this.campaigns[count].campaign.status === 'COMPLETE') {
                        this.complete.push(this.campaigns[count])
                    }
                    else if(this.campaigns[count].campaign.status === 'PAUSED'){
                        this.paused.push(this.campaigns[count])
                    }
                    else if (this.campaigns[count].campaign.status === 'INTERRUPTED') {
                        this.interrupted.push(this.campaigns[count]);
                    }
                }
                this.display = false
                this.athentication = true
                console.log(this.athentication)


            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    } else if(statusCode === 401){
                        this.$router.push({ name: 'Login' });     
                        return 401      
   
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.display = false
                this.athentication = true
                console.log(this.athentication)
            }

        },
        closeAlert() {
            this.danger = false
        },
        closeSucess() {
            this.sucess = false
        },
        async updatePlayPause(status, campaign_id){
            this.isLoading = true
            let update_status = {
                    status : status,
                    complete: false
                }
            try {
                await axios.patch(`/api/v1/campaigns/update/${campaign_id}`,update_status,{
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }
                })           
                this.isLoading = false
                window.location.reload()
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"
                    } else if(statusCode === 401){
                        this.$router.push({ name: 'Login' });        
                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.isLoading = false
            }
        },
        getCampaignById(campaignId) {
            let campaign = null;
            for (let tmpCampaign of this.campaigns) {
                if (tmpCampaign?.campaign?.id === campaignId) {
                    campaign = tmpCampaign;
                    break;
                }
            }
            if (!campaign) {
                throw new Error(`Camapaign with ID ${campaignId} not found`)
            }
        },
        async interruptCampaign(campaign_id){
            try {
                const confirmed = confirm(`You are going to interrupt campaign ${this.getCampaignById(campaign_id)?.name}`)
                if (!confirmed) {
                    return;
                }
            } catch(e) {
                console.log(`Campaign with ID ${campaign_id} not found`);
                return;
            }
            this.isLoading = true
            try {
                await axios.get(`/api/v1/campaigns/${campaign_id}/interrupt`, {
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }
                })        
                this.isLoading = false  
                window.location.reload()
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"
                    } else if(statusCode === 401){
                        this.$router.push({ name: 'Login' });        
                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.isLoading = false
            }

        },
        async deleteCampaign(campaign_id){
            try {
                const confirmed = confirm(`You are going to delete campaign ${this.getCampaignById(campaign_id)?.name}`)
                if (!confirmed) {
                    return;
                }
            } catch(e) {
                console.log(`Campaign with ID ${campaign_id} not found`);
                return;
            }
            this.isLoading = true
            try {
                await axios.delete(`/api/v1/campaigns/delete/campaign/${campaign_id}`,{
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }
                })   
                this.isLoading = false        
                window.location.reload()       
            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    } else if(statusCode === 401){
                        this.$router.push({ name: 'Login' });  
                    } else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.isLoading = false
            }
        },
        async downloadResult(campaign) {
            this.isLoading = true
            try {
                const response = await axios.get(`/api/v1/campaigns/${campaign.id}/results.csv`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    },
                    responseType: 'blob'
                });
                const blob = new Blob([response.data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `campaign_${campaign.name}_data.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.message = 'File downloaded successfully';
                this.sucess = true;
                this.danger = false;
                this.isLoading = false


            } catch (error) {
                if (error.response) {
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = "Campaign not found (404)"
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isLoading = false
            }
        },
        async restartFailedUrls(campaign_id) {
            this.isInterupt = true
            try {
                await axios.get(`/api/v1/campaigns/${campaign_id}/restart_failed_tasks`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }
                })
                window.location.reload()
            } catch (error) {
                if (error.response) {
                    console.log(error)
                    const statusCode = error.response.status;
                    if (statusCode === 404) {
                        this.message = error.response.data.detail
                        console.log(this.message)
                    } else if (statusCode === 400) {
                        this.message = "Bad request (400)"

                    } else if (statusCode === 500) {
                        this.message = "Server error (500)"
                    }
                    else if (statusCode === 401) {
                        this.$router.push({ name: 'Login' });
                    }
                    else {
                        this.message = `Error: ${statusCode}`
                    }
                } else if (error.request) {
                    this.message = "No response received from server"

                } else {
                    this.message = `Request setup error ${error.message}`
                }
                this.danger = true
                this.sucess = false
                this.isInterupt = false
            }
        },
        nextPage(){
            this.currentPage = this.currentPage + 1
            this.offset = this.currentPage * this.limit 
        },
        prevPage(){
            this.currentPage = this.currentPage - 1
            this.offset = this.currentPage * this.limit 
        },
        logOut(){
            localStorage.clear()
            window.location.reload()
        }
    },
    async mounted() {
        try {
            this.token = JSON.parse(localStorage.getItem('token'))
            this.currentPage = parseInt(this.$route.query.page, 10) | 0
            this.offset = this.currentPage * this.limit
            var response = await this.getCampaigns();
            if (response === 401){
                this.$router.push({ name: 'Login' }); 
                return
            }
            if(this.campaigns.length === 0 && this.currentPage !== 0)
            {
                this.$router.push({ name: 'Home' }); 
                setTimeout(()=>{
                    window.location.reload()        
                },1000)  
            }

        } catch (error) {
            this.$router.push({ name: 'Login' });   
            return     
        }
        if(this.token === null)
        {
            this.$router.push({ name: 'Login' });        
            return
        }
    },
    watch: {
    '$route.query.page': {
        handler() {
            this.pending = []
            this.paused = []
            this.complete = []
            this.inProgress = []
            this.getCampaigns();
        },
            immediate: false, 
        },
    }

}
</script>
